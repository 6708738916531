@mixin small {
    @media only screen and (max-width: 500px) {
      @content;
    }
  }
  @mixin tablet {
    @media only screen and (min-width: 768px) {
      @content;
    }
  }
  
  @mixin desktop {
    @media only screen and (min-width: 992px) {
      @content;
    }
  }