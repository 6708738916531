@use './mixin.scss' as mixin;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;600&display=swap');
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}
*,
:after,
:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
}
textarea,
input,
button,
select {
  font-family: inherit;
  font-size: inherit;
}
button {
  cursor: pointer;
}
textarea,
input,
button,
select {
  font-family: inherit;
  font-size: inherit;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

body {
  background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

.form {
  background-color: #fff;
  width: 28rem;
  height: 34rem;
  -webkit-box-shadow: 6px 8px 15px -10px rgba(66, 68, 90, 1);
  -moz-box-shadow: 6px 8px 15px -10px rgba(66, 68, 90, 1);
  box-shadow: 6px 8px 15px -10px rgba(66, 68, 90, 1);
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mixin.small {
      width: 100vw;
  }
}